import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/Header";
import { useEffect } from "react";
import Translation from "../../data/data.json";
import Footer from "../../components/Footer";

const getLocalLang = () => {
  let lang = localStorage.getItem("lang");

  if (lang) {
    return localStorage.getItem("lang");
  } else {
    return "EN";
  }
};

const ProjectDetail = () => {
  let { id } = useParams();
  const nextUrl = parseInt(id) + 1;

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  const content =
    getLocalLang() === "EN" ? Translation.english : Translation.mongolia;

  return (
    <>
      <Header />
      <Container>
        <div className="detail-wrap">
          <section>
            <div className="container">
              <div className="title">
                <p>{content?.projects.project[id].name}</p>
                <h5>{content?.projects.project[id].detail_title}</h5>
              </div>
            </div>
          </section>
          <div className="image">
            <img src={content?.projects.project[id].banner1} alt="banner" />
          </div>
          <section>
            <div className="container">
                <div className="main">
                  <div className="left">
                    <h5>{content?.projects.project[id].summary.title}</h5>
                  </div>
                  <div className="right">
                    <p>{content?.projects.project[id].summary.text}</p>
                    <Link
                      to={content?.projects.project[id].summary.web_url}
                      target="__blank"
                    >
                      <button>
                        {content?.projects.project[id].summary.button_text}
                      </button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                      >
                        <path
                          d="M 1.346 8.668 L 13.057 8.668 L 10.862 10.86 C 10.232 11.49 11.177 12.437 11.807 11.807 L 13.805 9.807 L 15.141 8.471 C 15.4 8.211 15.4 7.789 15.141 7.529 L 11.805 4.193 C 11.678 4.062 11.503 3.99 11.321 3.991 C 11.049 3.992 10.804 4.158 10.703 4.41 C 10.602 4.663 10.664 4.952 10.86 5.14 L 13.06 7.332 L 1.31 7.332 C 0.386 7.38 0.454 8.713 1.346 8.668 Z"
                          fill="#fcfcfc"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
            </div>
          </section>
          <div className="image">
            <img src={content?.projects.project[id].banner2} alt="banner" />
          </div>
          <section>
            <div className="container">
                <div className="main">
                  <div className="left">
                    <h5>{content?.projects.project[id].challenge.title}</h5>
                  </div>
                  <div className="right">
                    <p>{content?.projects.project[id].challenge.text}</p>
                  </div>
                </div>
            </div>
          </section>
          <div className="image">
            <img src={content?.projects.project[id].banner3} alt="banner" />
          </div>
          <section>
            <div className="container">
                <div className="main">
                  <div className="left">
                    <h5>{content?.projects.project[id].solution.title}</h5>
                  </div>
                  <div className="right">
                    <p>{content?.projects.project[id].solution.text}</p>
                  </div>
                </div>
            </div>
          </section>
          <section
            style={{
              backgroundColor: `${content?.projects.project[id].color_text.background}`,
            }}
          >
            <div className="container">
                <div
                  className="text"
                  style={{
                    color: `${content?.projects.project[id].color_text.color}`,
                  }}
                >
                  {content?.projects.project[id].color_text.text}
                </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="box-wrap">
                {content?.projects.project[0].adventage.map((data, i) => (
                    <div className="box" key={i}>
                      <div className="img">
                        <img src={data.img} alt="banner" />
                      </div>
                      <p>{data.text}</p>
                    </div>
                ))}
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="next-button">
                {content?.projects.project.length <= nextUrl ? (
                  <Link to={`/project/0`}>
                    <button>Next ideated pixel</button>
                  </Link>
                ) : (
                  <Link to={`/project/${nextUrl}`}>
                    <button>Next ideated pixel</button>
                  </Link>
                )}
              </div>
            </div>
          </section>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ProjectDetail;

const Container = styled.div`
  font-family: "Inter", "Inter Placeholder", sans-serif;
  .detail-wrap {
    padding-top: 15rem;
    section {
      padding: 12rem 0;
      .container {
        .title {
          display: flex;
          flex-direction: column;
          gap: 3rem;
          p {
            font-size: 18px;
            font-weight: 600;
            letter-spacing: -1px;
            text-transform: uppercase;
            color: #cacbcb;
            margin: 0;
          }
          h5 {
            font-size: 50px;
            font-weight: 700;
            color: #fcfcfc;
            letter-spacing: -1px;
            margin: 0;
            width: 40%;
          }
        }
        .main {
          display: flex;
          gap: 3rem;
          .left {
            width: 35%;
            h5 {
              font-size: 28px;
              font-weight: 600;
              color: #fcfcfc;
              margin: 0;
              letter-spacing: -1px;
            }
          }
          .right {
            width: 65%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            p {
              font-size: 16px;
              font-weight: 400;
              color: #cacbcb;
              margin: 0;
              letter-spacing: -1px;
              line-height: 180%;
            }
            a {
              text-decoration: none;
              display: flex;
              gap: 8px;
              align-items: center;
              button {
                font-size: 16px;
                color: #cacbcb;
                background: none;
                letter-spacing: -1px;
                font-weight: 500;
                border: none;
                padding: 5px 0;
                width: max-content;
                cursor: pointer;
              }
              &:hover {
                transition: 0.3s;
                button {
                  border-bottom: 1px solid #0088ff;
                  color: #0088ff;
                }
                svg {
                  path {
                    fill: #0088ff;
                  }
                }
              }
            }
          }
        }
        .text {
          font-size: 50px;
          letter-spacing: -1px;
          line-height: 130%;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
        }
        .box-wrap {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .box {
            width: 48%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-bottom: 3rem;
            .img {
              width: 100%;
              height: 678px;
              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 15px;
              }
            }
            p {
              margin: 0;
              font-weight: 400;
              font-size: 18px;
              letter-spacing: -1px;
              color: #fcfcfc;
            }
          }
        }
        .next-button {
          width: 100%;
          display: flex;
          justify-content: center;
          cursor: pointer;
          button {
            font-size: 28px;
            color: #fcfcfc;
            letter-spacing: -1px;
            border-bottom: 1px solid #fcfcfc;
            border-top: none;
            border-right: none;
            border-left: none;
            background: none;
            padding: 5px 0;
            width: max-content;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
      }
    }
    .image {
      height: 50rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @media screen and (max-width: 996px) {
    .detail-wrap {
      section {
        padding: 6rem 0;
        .container {
          .title {
            gap: 0;
            h5 {
              font-size: 24px;
              width: 100%;
              line-height: 1.4;
            }
          }
          .main {
            flex-direction: column;
            .left {
              width: 100%;
            }
            .right {
              width: 100%;
            }
          }
          .text {
            font-size: 20px;
          }
          .box-wrap {
            width: 100%;
            .box {
              width: 100%;
              .img {
                height: auto;
              }
            }
          }
          .next-button {
            button {
              font-size: 24px;
            }
          }
        }
      }
      .image {
        height: 30vh;
      }
    }
  }

  @media screen and (min-width: 1500px){
    .detail-wrap{
      section{
        .container{
          width: inherit;
          margin: 0 24rem;
          .title{
            p{
              font-size: 22px;
            }
            h5{
              font-size: 70px;
            }
          }
          .main{
            .left{
              h5{
                font-size: 38px;
              }
            }
            .right{
              p{
                font-size: 22px;
              }
            }
          }
          .text{
            font-size: 70px;
          }
          .box-wrap{
            .box{
              p{
                font-size: 22px;
              }
            }
          }
          .next-button{
            a{
              button{
                font-size: 38px;
              }
            }
          }
        }
      }
    }
  }
`;
