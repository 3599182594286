import styled from "styled-components";
import Translation from "../data/data.json";
import React, { useState } from "react";
// import nodemailer from "nodemailer"
// // const nodemailer = require("nodemailer");

const getLocalLang = () => {
  let lang = localStorage.getItem("lang");

  if (lang) {
    return localStorage.getItem("lang");
  } else {
    return "EN";
  }
};

const Contact = ({ setOpen }) => {
  const content =
    getLocalLang() === "EN" ? Translation.english : Translation.mongolia;

  const [toggleState, setToggleState] = useState(0);
  const handleClick = (e) => {
    setToggleState(e);
  };

  const wrapperRef = React.useRef();
  const handleClose = (e) => {
    if (wrapperRef.current === e.target) {
      wrapperRef.current.classList.add("hide");
      setTimeout(() => {
        setOpen(false);
      }, 400);
    }
  };

  // const sendMail = async () => {
  //   let transporter = nodemailer.createTransport({
  //     host: "mx.tavanbogd.com ",
  //     port: 587,
  //     secure: false, 
  //     auth: {
  //       user: "contact.siro@tavanbogd.com",
  //       pass: "Xshell5."
  //     },
  //   });
  //   let info = await transporter.sendMail({
  //     from: '"Fred Foo 👻" <foo@example.com>', 
  //     to: "bar@example.com, baz@example.com",
  //     subject: "Hello ✔", 
  //     text: "Hello world?", 
  //     html: "<b>Hello world?</b>"
  //   });

  //   console.log("mail error kk:", info.messageId)
  // }

  const [formState, setFormState] = useState({});

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const config = {
      Username: "contact.siro@tavanbogd.com",
      Password: "Xshell5.",
      // Password: "887752245EC602FC1F579525509205C7F341",
      Host: "mx.tavanbogd.com",
      Port: 587,
      // SecureToken: "7c715ae1-12ed-4414-a391-c0d9b9f6d04e",
      To: "gangaa.n@tavanbogd.com",
      From: formState.email,
      Subject: `${
        toggleState === 1 ? "Working" : toggleState === 2 ? "Co-Working" : ""
      }`,
      Body: `${formState.text} <br> Утас: ${formState.phone} <br>И-мэйл: ${formState.email}`,
    };

    if (window.Email) {
      window.Email.send(config).then(() =>
        alert("И-мэйл амжилттай илгээгдлээ.")
      ).catch(error => {
        console.log("mail error kk:", error)
      }) ;
    }
  };

  return (
    <Container ref={wrapperRef} onClick={handleClose}>
      <div className="contact-wrap">
        <button className="x" onClick={() => setOpen(false)}>
          <img src="/img/x.svg" alt="icon" />
        </button>
        <div className="contact">
            <div className="title">{content?.home.contact.title}</div>
            <div className="bottom-wrap">
              <div className="left-wrap">
                <h5>{content?.home.contact.text}</h5>
                <div className="info-wrap">
                  <div className="info">
                    <h1>{content?.home.contact.mail}</h1>
                    <p>{content?.home.contact.mail1}</p>
                  </div>
                  <div className="info">
                    <h1>{content?.home.contact.phone}</h1>
                    <p>+{content?.home.contact.phone1}</p>
                  </div>
                  <div className="info">
                    <h1>{content?.home.contact.location}</h1>
                    <p>{content?.home.contact.location1}</p>
                  </div>
                </div>
              </div>
              <div className="right-wrap">
                <div className="select">
                  <div className="title">
                    {content?.home.contact.form.text1}
                  </div>
                  <div className="button-wrap">
                    <button
                      className={`${toggleState === 1 ? "active" : ""}`}
                      onClick={() => handleClick(1)}
                    >
                      {content?.home.contact.form.button1}
                    </button>
                    <button
                      className={`${toggleState === 2 ? "active" : ""}`}
                      onClick={() => handleClick(2)}
                    >
                      {content?.home.contact.form.button2}
                    </button>
                  </div>
                </div>
                <form onSubmit={submitHandler}>
                  <input
                    type="text"
                    name="text"
                    placeholder={content?.home.contact.form.text2}
                    className="text"
                    onChange={changeHandler}
                    value={formState.text || ""}
                  />
                  <div className="cc">
                    <input
                      type="email"
                      name="email"
                      placeholder={content?.home.contact.form.text3}
                      className="mail"
                      onChange={changeHandler}
                    />
                    <input
                      type="text"
                      name="phone"
                      placeholder={content?.home.contact.form.text4}
                      className="mail"
                      onChange={changeHandler}
                    />
                  </div>
                  <button type="submit">
                    {content?.home.contact.form.submit}
                  </button>
                </form>
              </div>
            </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;

const Container = styled.div`
  font-family: "Inter", sans-serif;
  position: fixed;
  left: 0;
  right: 0;
  top: 8rem;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  &.hide {
    animation: fade-out 1s ease-out both;
  }
  .contact-wrap {
    padding: 8rem 10rem;
    background-color: #04030c;
    border: 1px solid #fcfcfc;
    width: 1100px;
    height: auto;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .x {
      border-radius: 30px;
      width: 35px;
      height: 35px;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }
    .contact {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 7rem;
      .title {
        color: #fcfcfc;
        font-size: 36px;
        font-weight: 600;
        letter-spacing: -1px;
      }
      .bottom-wrap {
        display: flex;
        .left-wrap {
          width: 50%;
          gap: 3rem;
          display: flex;
          flex-direction: column;
          h5 {
            width: 90%;
            color: #cacbcb;
            font-size: 16px;
            font-weight: 400;
            line-height: 180%;
            letter-spacing: -1px;
            margin: 0;
          }
          .info-wrap {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .info {
              h1 {
                color: #cacbcb;
                font-size: 16px;
                font-weight: 600;
                line-height: 180%;
                letter-spacing: -1px;
                margin: 0;
              }
              p {
                color: #cacbcb;
                font-size: 16px;
                font-weight: 400;
                line-height: 180%;
                letter-spacing: -1px;
                margin: 0;
              }
            }
          }
        }
        .right-wrap {
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 15px;
          .select {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .title {
              width: auto;
              padding: 16px 24px;
              font-weight: 400;
              font-size: 14px;
              color: white;
            }
            .button-wrap {
              gap: 1rem;
              display: flex;
              button {
                padding: 16px 24px;
                width: auto;
                font-weight: 400;
                font-size: 14px;
                color: white;
                border-radius: 15px;
                border: 1px solid rgb(245, 245, 247);
                background: rgba(0, 0, 0, 0);
                &:hover {
                  background-color: rgb(245, 245, 247);
                  color: #04030c;
                  transition: 0.4s;
                }
              }
              .active {
                background-color: rgb(245, 245, 247);
                color: #04030c;
              }
            }
          }
          form {
            display: flex;
            flex-direction: column;
            gap: 15px;
            input {
              background: #191919;
              height: 53px;
              border-radius: 15px;
              will-change: transform;
              border: none;
              padding: 0 24px;
              color: rgb(245, 245, 247);
              font-weight: 400;
              font-size: 14px;
              &:focus {
                outline: none;
              }
              &::placeholder {
                color: rgb(245, 245, 247);
                font-weight: 400;
                font-size: 14px;
              }
            }
            .cc {
              display: flex;
              gap: 15px;
              input {
                width: 50%;
                background: #191919;
                height: 53px;
                border-radius: 15px;
                will-change: transform;
                border: none;
                padding: 0 24px;
                color: rgb(245, 245, 247);
                font-weight: 400;
                font-size: 14px;
                &:focus {
                  outline: none;
                }
                &::placeholder {
                  color: rgb(245, 245, 247);
                  font-weight: 400;
                  font-size: 14px;
                }
              }
            }
            button {
              padding: 16px 24px;
              background: #fff;
              color: #04030c;
              border: none;
              border-radius: 15px;
              font-size: 15px;
              font-weight: 400;
              width: fit-content;
              cursor: pointer;
              &:hover {
                color: rgb(10, 157, 91);
                transition: 0.3s;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 996px) {
    top: 3rem;
    .contact-wrap {
      padding: 30px 15px;
      width: 90%;
      overflow-y: auto;
      gap: 15px;
      .x {
        width: 30px;
        height: 30px;
      }
      .contact {
        width: 100%;
        gap: 3rem;
        .title {
          font-size: 24px;
        }
        .bottom-wrap {
          flex-direction: column;
          width: 100%;
          .left-wrap {
            width: 100%;
            gap: 15px;
            h5 {
              width: 100%;
              font-size: 12px;
            }
            .info-wrap {
              .info {
                h1 {
                  font-size: 12px;
                }
                p {
                  font-size: 12px;
                }
              }
            }
          }
          .right-wrap {
            width: 100%;
            margin-top: 15px;
            .select {
              width: 100%;
              .title {
                display: none;
              }
              .button-wrap {
                button {
                  padding: 10px 16px;
                  font-size: 12px;
                }
              }
            }
            form {
              width: 100%;
              input {
                font-size: 12px;
                &::placeholder {
                  color: rgb(245, 245, 247);
                  font-weight: 400;
                  font-size: 12px;
                }
              }
              .cc {
                flex-direction: column;
                input {
                  width: 100%;
                  font-size: 12px;
                  &::placeholder {
                    color: rgb(245, 245, 247);
                    font-weight: 400;
                    font-size: 12px;
                  }
                }
              }
              button {
                padding: 10px 16px;
                font-size: 12px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  @-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
