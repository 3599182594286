import styled from "styled-components";

const ComingSoon = () => {
  return (
    <>
      <Container>
        <div className="header">
          <div className="container">
            <div className="logo">
              <img src="/img/sirologowhite.svg" alt="Logo" />
            </div>
          </div>
        </div>
        <div class="wrapper">
          <h1>
            coming soon<span class="dot">.</span>
          </h1>
          <p>WE MAKE YOUR IDEAS.</p>
          <div class="icons">
            <a href="https://www.facebook.com/SiroDigital" target="__blank">
              <i class="fa fa-facebook"></i>
            </a>
            <a href="">
              <i class="fa fa-linkedin"></i>
            </a>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ComingSoon;

const Container = styled.div`
  height: 100vh;
  background: #00091b;
  color: #fff;
  .header {
    .container {
      .logo {
        padding: 1.5rem 0;
        img {
          width: 60px;
          height: 30px;
        }
      }
    }
  }
  .wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    animation: fadeIn 1000ms ease;
    -webkit-animation: fadeIn 1000ms ease;
    h1 {
      font-size: 50px;
      font-family: "Poppins", sans-serif;
      margin-bottom: 0;
      line-height: 1;
      font-weight: 700;
      text-align: center;
      .dot {
        color: #4febfe;
      }
    }
    p {
      text-align: center;
      margin: 18px;
      font-family: "Muli", sans-serif;
      font-weight: normal;
    }
    .icons {
      text-align: center;
      i {
        color: #00091b;
        background: #fff;
        height: 44px;
        width: 44px;
        padding: 13px;
        margin: 0 10px;
        border-radius: 50px;
        border: 2px solid #fff;
        transition: all 200ms ease;
        text-decoration: none;
        position: relative;
        &:hover,
        &:active {
          color: #fff;
          background: none;
          cursor: pointer !important;
          transform: scale(1.2);
          -webkit-transform: scale(1.2);
          text-decoration: none;
        }
      }
    }
  }
  @keyframes fadeIn {
    from {
      top: 20%;
      opacity: 0;
    }
    to {
      top: 100;
      opacity: 1;
    }
  }
  @media screen and (max-width: 996px) {
  }
`;
