import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Translation from "../../data/data.json";
import { useEffect } from "react";

const getLocalLang = () => {
  let lang = localStorage.getItem("lang");

  if (lang) {
    return localStorage.getItem("lang");
  } else {
    return "EN";
  }
};

const About = () => {
  const content =
    getLocalLang() === "EN" ? Translation.english : Translation.mongolia;

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <>
      <Header />
      <Container>
        <section>
          <div className="container">
              <div className="content-wrap">
                <h1>{content?.about.title}</h1>
                <div className="text">
                  <p>{content?.about.text}</p>
                </div>
              </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="title">
              <div className="line">
                <div className="bg">
                  <h5>{content?.about.partner_title}</h5>
                </div>
              </div>
            </div>
              <div className="content-wrap">
                <div className="text">
                  <p>{content?.about.partner_text}</p>
                </div>
                <div className="partner-wrp">
                    {content?.about.partner.map((data, i) => (
                      <div className="wrap" key={i}>
                        <Link href={data.url}>
                          <div className="image">
                            <img src={data.img} alt="slide2" />
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
          </div>
        </section>
      </Container>
      <Footer />
    </>
  );
};

export default About;

const Container = styled.div`
  font-family: "Inter", sans-serif;
  section {
    height: 100vh;
    display: flex;
    align-items: center;
    .container {
      display: flex;
      flex-direction: column;
      gap: 4rem;
      .title {
        width: 100%;
        .line {
          width: 100%;
          height: 1px;
          position: relative;
          background-color: #fcfcfc;
          .bg {
            position: absolute;
            top: -17px;
            background-color: #000;
            padding-right: 24px;
            h5 {
              color: #fcfcfc;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 1px;
              width: max-content;
              text-transform: uppercase;
              font-family: "IBM Plex Mono", monospace;
            }
          }
        }
      }
      .content-wrap {
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 4rem;
        h1 {
          font-size: 50px;
          letter-spacing: -1px;
          line-height: 1.3;
          color: #fcfcfc;
          font-weight: 700;
          margin: 0;
        }
        .text {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          p {
            color: #cacbcb;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.6;
            margin: 0;
            letter-spacing: -1px;
          }
        }
        .partner-wrp {
          display: flex;
          flex-wrap: wrap;
          gap: 6rem;
          .wrap {
            width: auto;
            height: 50px;
            .image {
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 996px) {
    section {
      height: 120vh;
      .container {
        .title {
          .line {
            width: 65%;
          }
        }
        .content-wrap {
          width: 100%;
          h1 {
            font-size: 35px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    section {
      .container {
        width: 100%;
        margin: 0 24rem;
        .content-wrap {
          width: 70%;
          h1 {
            font-size: 70px;
          }
          .text {
            p {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
`;
