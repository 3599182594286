import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/Header";
import Translation from "../../data/data.json";
import { useEffect, useState } from "react";
import Footer from "../../components/Footer";

const getLocalLang = () => {
  let lang = localStorage.getItem("lang");

  if (lang) {
    return localStorage.getItem("lang");
  } else {
    return "EN";
  }
};

const Project = () => {
  const content =
    getLocalLang() === "EN" ? Translation.english : Translation.mongolia;

  const [toggleState, setToggleState] = useState(0);
  const handleClick = (e) => {
    setToggleState(e);
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <>
      <Header />
      <Container>
        <section>
          <div className="container">
              <div className="title-wrap">
                <div className="title">
                  <h1>{content?.projects.title}</h1>
                  <p>{content?.projects.text}</p>
                </div>
              </div>
            <div className="project-wrap">
              <div className="tabs">
                {content?.projects.project_type.map((data, i) => (
                  <div
                    key={i}
                    className={`tab ${toggleState === i ? "active" : ""}`}
                    onClick={() => handleClick(i)}
                  >
                    <h5>{data.name}</h5>
                  </div>
                ))}
              </div>
              <div className="project">
                {toggleState === 0
                  ? content?.projects.project.map((data, i) => (
                        <div className="wrap" key={i}>
                          <div className="wrp">
                            <div className="image">
                              <Link to={`/project/${data.id}`}>
                                <img src={data.banner} alt="slider" />
                              </Link>
                            </div>
                            <div className="text">
                              <div className="text-t">
                                <h5>{data.name}</h5>
                                <Link to={`/project/${data.id}`}>
                                  <div className="url">
                                    <p>View</p>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14.689"
                                      height="8.023"
                                    >
                                      <path
                                        d="M 0.699 4.677 L 12.411 4.677 L 10.216 6.869 C 9.586 7.499 10.531 8.446 11.161 7.816 L 13.159 5.815 L 14.495 4.48 C 14.754 4.219 14.754 3.798 14.495 3.538 L 11.159 0.202 C 11.032 0.071 10.857 -0.002 10.675 0 C 10.403 0.001 10.158 0.167 10.057 0.419 C 9.956 0.672 10.018 0.96 10.214 1.149 L 12.413 3.341 L 0.664 3.341 C -0.261 3.389 -0.193 4.722 0.699 4.677 Z"
                                        fill="#fcfcfc"
                                      ></path>
                                    </svg>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                    ))
                  : toggleState === 1
                  ? content?.projects.project.map((data, i) =>
                      data.type === "Discovery" ? (
                          <div className="wrap" key={i}>
                            <div className="wrp">
                              <div className="image">
                                <Link to={`/project/${data.id}`}>
                                  <img src={data.banner} alt="slider" />
                                </Link>
                              </div>
                              <div className="text">
                                <div className="text-t">
                                  <h5>{data.name}</h5>
                                  <Link to={`/project/${data.id}`}>
                                    <div className="url">
                                      <p>View</p>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.689"
                                        height="8.023"
                                      >
                                        <path
                                          d="M 0.699 4.677 L 12.411 4.677 L 10.216 6.869 C 9.586 7.499 10.531 8.446 11.161 7.816 L 13.159 5.815 L 14.495 4.48 C 14.754 4.219 14.754 3.798 14.495 3.538 L 11.159 0.202 C 11.032 0.071 10.857 -0.002 10.675 0 C 10.403 0.001 10.158 0.167 10.057 0.419 C 9.956 0.672 10.018 0.96 10.214 1.149 L 12.413 3.341 L 0.664 3.341 C -0.261 3.389 -0.193 4.722 0.699 4.677 Z"
                                          fill="#fcfcfc"
                                        ></path>
                                      </svg>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                      ) : (
                        ""
                      )
                    )
                  : toggleState === 2
                  ? content?.projects.project.map((data, i) =>
                      data.type === "Design" ? (
                          <div className="wrap" key={i}>
                            <div className="wrp">
                              <div className="image">
                                <Link to={`/project/${data.id}`}>
                                  <img src={data.banner} alt="slider" />
                                </Link>
                              </div>
                              <div className="text">
                                <div className="text-t">
                                  <h5>{data.name}</h5>
                                  <Link to={`/project/${data.id}`}>
                                    <div className="url">
                                      <p>View</p>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.689"
                                        height="8.023"
                                      >
                                        <path
                                          d="M 0.699 4.677 L 12.411 4.677 L 10.216 6.869 C 9.586 7.499 10.531 8.446 11.161 7.816 L 13.159 5.815 L 14.495 4.48 C 14.754 4.219 14.754 3.798 14.495 3.538 L 11.159 0.202 C 11.032 0.071 10.857 -0.002 10.675 0 C 10.403 0.001 10.158 0.167 10.057 0.419 C 9.956 0.672 10.018 0.96 10.214 1.149 L 12.413 3.341 L 0.664 3.341 C -0.261 3.389 -0.193 4.722 0.699 4.677 Z"
                                          fill="#fcfcfc"
                                        ></path>
                                      </svg>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                      ) : (
                        ""
                      )
                    )
                  : toggleState === 3
                  ? content?.projects.project.map((data, i) =>
                      data.type === "Development" ? (
                          <div className="wrap" key={i}>
                            <div className="wrp">
                              <div className="image">
                                <Link to={`/project/${data.id}`}>
                                  <img src={data.banner} alt="slider" />
                                </Link>
                              </div>
                              <div className="text">
                                <div className="text-t">
                                  <h5>{data.name}</h5>
                                  <Link to={`/project/${data.id}`}>
                                    <div className="url">
                                      <p>View</p>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.689"
                                        height="8.023"
                                      >
                                        <path
                                          d="M 0.699 4.677 L 12.411 4.677 L 10.216 6.869 C 9.586 7.499 10.531 8.446 11.161 7.816 L 13.159 5.815 L 14.495 4.48 C 14.754 4.219 14.754 3.798 14.495 3.538 L 11.159 0.202 C 11.032 0.071 10.857 -0.002 10.675 0 C 10.403 0.001 10.158 0.167 10.057 0.419 C 9.956 0.672 10.018 0.96 10.214 1.149 L 12.413 3.341 L 0.664 3.341 C -0.261 3.389 -0.193 4.722 0.699 4.677 Z"
                                          fill="#fcfcfc"
                                        ></path>
                                      </svg>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                      ) : (
                        ""
                      )
                    )
                  : ""}
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Footer />
    </>
  );
};

export default Project;

const Container = styled.div`
  font-family: "Inter", "Inter Placeholder", sans-serif;
  section {
    width: 100%;
    .container {
      .title-wrap {
        padding-top: 24rem;
        padding-bottom: 7rem;
        display: flex;
        justify-content: center;
        width: 100%;
        .title {
          width: 60%;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          h1 {
            margin: 0;
            font-size: 50px;
            font-weight: 700;
            letter-spacing: -1px;
            color: #fcfcfc;
            text-align: center;
            line-height: 110%;
          }
          p {
            margin: 0;
            text-align: center;
            letter-spacing: -1px;
            font-size: 16px;
            color: #cacbcb;
            font-weight: 400;
          }
        }
      }
      .project-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
        .tabs {
          display: flex;
          gap: 12px;
          .tab {
            border-radius: 30px;
            border: 1px solid #fcfcfc;
            padding: 10px 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            h5 {
              color: #fcfcfc;
              font-size: 13px;
              font-weight: 600;
              margin: 0;
            }
            &:hover {
              background-color: #fcfcfc;
              h5 {
                color: #000503;
              }
            }
          }
          .active {
            background-color: #fcfcfc;
            h5 {
              color: #000503;
            }
          }
        }
        .project {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          .wrap {
            height: 50rem;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .wrp {
              height: 100%;
              .image {
                width: 100%;
                height: 90%;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 15px;
                }
              }
              .text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 10%;
                .text-t {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  h5 {
                    margin: 0;
                    color: #fcfcfc;
                    font-size: 16px;
                    letter-spacing: -1px;
                  }
                  a {
                    text-decoration: none;
                    .url {
                      display: flex;
                      align-items: center;
                      gap: 5px;
                      p {
                        margin: 0;
                        color: #fcfcfc;
                        font-size: 16px;
                        letter-spacing: -1px;
                      }
                      &:hover {
                        color: rgb(10, 157, 91);
                        transition: 0.3s;
                        svg {
                          path {
                            transition: 0.3s;
                            fill: rgb(10, 157, 91);
                          }
                        }
                      }
                      p {
                        margin: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 996px) {
    section {
      .container {
        .title-wrap {
          .title {
            width: 100%;
            h1 {
              font-size: 36px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 628px) {
    section {
      .container {
        .project-wrap {
          .tabs {
            flex-direction: column;
            width: 100%;
            .tab {
              width: 100%;
            }
          }
          .project {
            .wrap {
              height: 30rem;
              .wrp {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1500px){
    section{
      width: 100%;
      .container{
        width: initial;
        margin: 0 24rem;
        .title-wrap{
          .title{
            width: 80%;
            h1{
              font-size: 70px;
            }
            p{
              font-size: 22px;
            }
          }
        }
        .project-wrap{
          .tabs{
            .tab{
              padding: 16px 24px;
            }
          }
          .project{
            .wrap{
              .wrp{
                .text{
                  .text-t{
                    h5{
                      font-size: 22px;
                    }
                    a{
                      .url{
                        p{
                          font-size: 22px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @keyframes topFadeOut {
    0% {
      position: absolute;
      bottom: -1rem;
      opacity: 0;
    }

    75% {
      position: absolute;
      bottom: 0;
      opacity: 1;
    }
    100% {
      position: absolute;
      bottom: 0;
      opacity: 1;
    }
  }
`;
