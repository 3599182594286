import styled from "styled-components";
import HomeScroll from "./HomeScroll";
import Translation from "../data/data.json";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Card from "./Card";

const getLocalLang = () => {
  let lang = localStorage.getItem("lang");

  if (lang) {
    return localStorage.getItem("lang");
  } else {
    return "EN";
  }
};

const Home = () => {
  const content =
    getLocalLang() === "EN" ? Translation.english : Translation.mongolia;

  const location = useLocation();
  useEffect(() => {
    if (location.hash === "#contact") {
      document
        .getElementById("contactid")
        .scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const project = content?.projects.project;
  const last = project.length - 1;

  return (
    <>
      <HomeScroll />
      <Container>
        <section>
          <div className="container">
              <div className="title">
                <div className="line">
                  <div className="bg">
                    <h5>{content?.home.about_title}</h5>
                  </div>
                </div>
              </div>
              <div className="content-wrap">
                <h1>{content?.home.about_text_title}</h1>
                <div className="text">
                  <p>{content?.home.about_text}</p>
                  <Link to={"/about"}>
                    <button>
                      {content?.home.about_button}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                      >
                        <path
                          d="M 1.346 8.668 L 13.057 8.668 L 10.862 10.86 C 10.232 11.49 11.177 12.437 11.807 11.807 L 13.805 9.807 L 15.141 8.471 C 15.4 8.211 15.4 7.789 15.141 7.529 L 11.805 4.193 C 11.678 4.062 11.503 3.99 11.321 3.991 C 11.049 3.992 10.804 4.158 10.703 4.41 C 10.602 4.663 10.664 4.952 10.86 5.14 L 13.06 7.332 L 1.31 7.332 C 0.386 7.38 0.454 8.713 1.346 8.668 Z"
                          fill="#fcfcfc"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
          </div>
        </section>
        <section className="service">
          <div className="container">
              <div className="title">
                <div className="line">
                  <div className="bg">
                    <h5>{content?.home.service.title}</h5>
                  </div>
                </div>
              </div>
              <div className="content-wrap">
                <h1>{content?.home.service.text_title}</h1>
                <div className="text">
                  <p>{content?.home.service.text}</p>
                  <Link to={"/project"}>
                    <button>
                      {content?.home.service.button}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                      >
                        <path
                          d="M 1.346 8.668 L 13.057 8.668 L 10.862 10.86 C 10.232 11.49 11.177 12.437 11.807 11.807 L 13.805 9.807 L 15.141 8.471 C 15.4 8.211 15.4 7.789 15.141 7.529 L 11.805 4.193 C 11.678 4.062 11.503 3.99 11.321 3.991 C 11.049 3.992 10.804 4.158 10.703 4.41 C 10.602 4.663 10.664 4.952 10.86 5.14 L 13.06 7.332 L 1.31 7.332 C 0.386 7.38 0.454 8.713 1.346 8.668 Z"
                          fill="#fcfcfc"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="card-wrap">
                {content?.home.service.box.map((data, i) => (
                  <Card data={data} key={i} />
                ))}
              </div>
          </div>
        </section>
        <section>
          <div className="container">
              <div className="title-center">
                <div className="line">
                  <div className="bg">
                    <h5>{content?.home.latest_project.title}</h5>
                  </div>
                </div>
              </div>
              <div className="project-wrap">
                <h1>{content?.home.latest_project.value}</h1>
                <div className="project">
                  <Link to={`/project/${project[last].id}`}>
                    <img src={project[last].banner} alt="banner" />
                    <div className="bottom">
                      <h5>{project[last].name}</h5>
                      <div className="url">
                        <p>View</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                        >
                          <path
                            d="M 1.346 8.668 L 13.057 8.668 L 10.862 10.86 C 10.232 11.49 11.177 12.437 11.807 11.807 L 13.805 9.807 L 15.141 8.471 C 15.4 8.211 15.4 7.789 15.141 7.529 L 11.805 4.193 C 11.678 4.062 11.503 3.99 11.321 3.991 C 11.049 3.992 10.804 4.158 10.703 4.41 C 10.602 4.663 10.664 4.952 10.86 5.14 L 13.06 7.332 L 1.31 7.332 C 0.386 7.38 0.454 8.713 1.346 8.668 Z"
                            fill="#fcfcfc"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default Home;

const Container = styled.div`
  font-family: "Inter", sans-serif;
  section {
    height: 100vh;
    display: flex;
    align-items: center;
    .container {
      display: flex;
      flex-direction: column;
      gap: 4rem;
      .title {
        width: 100%;
        margin-bottom: 4rem;
        .line {
          width: 100%;
          height: 1px;
          position: relative;
          background-color: #fcfcfc;
          .bg {
            position: absolute;
            top: -17px;
            background-color: #000;
            padding-right: 24px;
            h5 {
              color: #fcfcfc;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 1px;
              width: max-content;
              text-transform: uppercase;
              font-family: "IBM Plex Mono", monospace;
            }
          }
        }
      }
      .title-center {
        width: 100%;
        margin-bottom: 4rem;
        .line {
          width: 100%;
          height: 1px;
          position: relative;
          background-color: #fcfcfc;
          .bg {
            position: absolute;
            top: -17px;
            background-color: #000;
            padding: 0 24px;
            margin: auto;
            width: max-content;
            display: flex;
            left: 0;
            right: 0;
            justify-content: center;
            h5 {
              color: #fcfcfc;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 1px;
              width: max-content;
              text-transform: uppercase;
              font-family: "IBM Plex Mono", monospace;
            }
          }
        }
      }
      .content-wrap {
        width: 65%;
        display: flex;
        flex-direction: column;
        gap: 4rem;
        h1 {
          font-size: 50px;
          letter-spacing: -1px;
          line-height: 1.3;
          color: #fcfcfc;
          font-weight: 700;
          margin: 0;
        }
        .text {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          p {
            color: #cacbcb;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.6;
            margin: 0;
            letter-spacing: -1px;
          }
          a {
            text-decoration: none;
            button {
              padding: 10px 16px;
              background-color: #000;
              color: #fcfcfc;
              border: none;
              border-radius: 30px;
              font-size: 16px;
              font-weight: 600;
              display: flex;
              align-items: center;
              letter-spacing: -1px;
              gap: 8px;
              width: fit-content;
              border: 1px solid #fcfcfc;
              cursor: pointer;
              &:hover {
                color: rgb(10, 157, 91);
                transition: 0.3s;
                background-color: #fff;
                svg {
                  path {
                    transition: 0.3s;
                    fill: rgb(10, 157, 91);
                  }
                }
              }
            }
          }
        }
      }
      .project-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
        h1 {
          font-size: 50px;
          letter-spacing: -1px;
          line-height: 1.3;
          color: #fcfcfc;
          font-weight: 700;
          margin: 0;
        }
        .project {
          a {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            text-decoration: none;
            img {
              height: 45rem;
              width: 100%;
              object-fit: cover;
              border-radius: 15px;
            }
            .bottom {
              display: flex;
              justify-content: space-between;
              h5 {
                color: #fcfcfc;
                font-size: 16px;
                letter-spacing: -1px;
                margin: 0;
              }
              .url {
                display: flex;
                align-items: center;
                gap: 12px;
                p {
                  color: #fcfcfc;
                  font-size: 16px;
                  letter-spacing: -1px;
                  margin: 0;
                }
              }
            }
          }
        }
      }
      .card-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
      }
    }
  }

  @media screen and (max-width: 996px) {
    section {
      height: 120vh;
      .container {
        .content-wrap {
          width: 100%;
          h1 {
            font-size: 35px;
          }
        }
        .card-wrap {
          width: 100%;
          flex-wrap: nowrap;
          flex-direction: column;
        }
        .project-wrap {
          h1 {
            font-size: 35px;
          }
        }
      }
    }
    .service {
      height: 100%;
    }
  }

  @media screen and (min-width: 1500px) {
    section {
      width: 100%;
      .container {
        width: inherit;
        margin: 0 24rem;
        .content-wrap {
          width: 90%;
          h1 {
            font-size: 70px;
          }
          .text {
            p {
              font-size: 22px;
            }
          }
        }
        .project-wrap {
          width: 100%;
          .project {
            width: 100%;
            a {
              .bottom {
                h5 {
                  font-size: 22px;
                }
                .url {
                  p {
                    font-size: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
