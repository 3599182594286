import styled from "styled-components";
import Translation from "../data/data.json";
import { useRive } from "rive-react";

const getLocalLang = () => {
  let lang = localStorage.getItem("lang");

  if (lang) {
    return localStorage.getItem("lang");
  } else {
    return "EN";
  }
};
const HomeSlider = () => {
  const content =
    getLocalLang() === "EN" ? Translation.english : Translation.mongolia;

  const { RiveComponent } = useRive({
    src: "https://rive.app/s/AWw7wzN0P0aGyoUj5syaVw/embed",
    autoplay: true,
    onLoadError: () => console.log("ERROR LOADING RIVE"),
    onLoad: () => console.log("LOADED RIVE"),
  });

  return (
    <Container>
      <div className="bg">
        <div className="container">
          <div className="animation">
            {/* <RiveComponent src="https://rive.app/s/AWw7wzN0P0aGyoUj5syaVw/embed" className="anim" /> */}
            <div className="anim">
              <iframe
                style={{ border: "none" }}
                width="100%"
                height="100%"
                src="https://rive.app/s/HGjJCs-iuUuwS7Tl5n-DYA/embed"
                allowfullscreen
              ></iframe>
            </div>
            <div className="wrap">
              <div className="text">
                <p>{content?.home.slider_title}</p>
                <h1>{content?.home.slider_text}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HomeSlider;

const Container = styled.div`
  @keyframes topFadeOut {
    0% {
      transform: translateY(15%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  .bg {
    height: 100vh;
    display: flex;
    align-items: center;
    .container {
      height: 100%;
      .animation {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .anim {
          height: 100% !important;
          width: 52rem !important;
          position: relative;
          border: none;
          z-index: -1;
        }
        .wrap {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          margin: auto 0;
          display: flex;
          z-index: 1;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;
            width: 45%;
            opacity: 1;
            animation-name: topFadeOut;
            animation-duration: 2s;
            p {
              font-size: 16px;
              font-weight: 400;
              color: #fcfcfc;
              text-transform: uppercase;
              margin: 0;
            }
            h1 {
              font-size: 60px;
              font-weight: 700;
              color: #fcfcfc;
              letter-spacing: -0.5px;
              text-transform: uppercase;
              margin: 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    .bg {
      .container {
        .animation {
          .anim {
            width: 30rem !important;;
          }
          .wrap {
            .text {
              width: 80%;
              h1 {
                font-size: 44px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .bg {
      .container {
        .animation {
          .anim {
            width: 80rem !important;;
          }
          .wrap {
            .text {
              h1 {
                font-size: 84px;
              }
              p {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }
`;
