import styled from "styled-components";
import Translation from "../data/data.json";
import { Link, useNavigate } from "react-router-dom";

const getLocalLang = () => {
  let lang = localStorage.getItem("lang");

  if (lang) {
    return localStorage.getItem("lang");
  } else {
    return "EN";
  }
};

const Footer = () => {
  const content =
    getLocalLang() === "EN" ? Translation.english : Translation.mongolia;

  let objectDate = new Date();
  let year = objectDate.getFullYear();

  const navigate = useNavigate();

  const handleClickButton = () => {
    navigate("/#contact");
    // document.getElementById("contactid").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container>
      <div className="container">
        <div className="footer-wrap">
          <div className="menu-wrap">
            <div className="logo">
              <img src="/img/sirowhite.svg" alt="Logo" />
            </div>
            <div className="menu">
              {content?.header.menulist.map((data, i) => (
                <Link to={data.url} key={i}>
                  <div className="list">{data.menu}</div>
                </Link>
              ))}
            </div>
          </div>
          <div className="social-wrap">
            <Link to="https://www.facebook.com/SiroDigital">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.0476 3H7.6875C5.1131 3 3 5.1131 3 7.70238V17.0625C3 19.6369 5.1131 21.75 7.6875 21.75H17.0476C19.6369 21.75 21.75 19.6369 21.75 17.0476V7.70238C21.75 5.1131 19.6369 3 17.0476 3ZM14.8899 12.375H13.119V18.1786H10.8869V12.375H9.69643V9.99405H10.7381V8.98214C10.7381 8.02976 11.2143 6.52679 13.2232 6.52679H15.0536V8.50595H13.7589C13.5506 8.50595 13.2679 8.63988 13.2679 9.10119V9.99405H15.0982L14.8899 12.375Z"
                  fill="#FCFCFC"
                ></path>
              </svg>
            </Link>
            <Link to="https://www.facebook.com/SiroDigital">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9 12C9 10.3428 10.3428 9 12 9C13.6572 9 15 10.3428 15 12C15 13.6572 13.6572 15 12 15C10.3428 15 9 13.6572 9 12Z"
                  fill="#FCFCFC"
                ></path>
                <path
                  d="M16.1255 2.62561H7.87549C6.48349 2.62561 5.14789 3.17881 4.16269 4.16281C3.17869 5.14801 2.62549 6.48361 2.62549 7.87561V16.1256C2.62549 19.0248 4.97629 21.3756 7.87549 21.3756H16.1255C19.0247 21.3756 21.3755 19.0248 21.3755 16.1256V7.87561C21.3755 4.97641 19.0247 2.62561 16.1255 2.62561ZM11.9999 16.5C9.51469 16.5 7.49989 14.4852 7.49989 12C7.49989 9.51481 9.51469 7.50001 11.9999 7.50001C14.4851 7.50001 16.4999 9.51481 16.4999 12C16.4999 14.4852 14.4851 16.5 11.9999 16.5ZM16.8755 8.25001C16.2539 8.25001 15.7499 7.74601 15.7499 7.12441C15.7499 6.50281 16.2539 5.99881 16.8755 5.99881C17.4971 5.99881 18.0011 6.50281 18.0011 7.12441C18.0011 7.74601 17.4971 8.25001 16.8755 8.25001Z"
                  fill="#FCFCFC"
                ></path>
              </svg>
            </Link>
          </div>
          <div className="copyright">
            <h5>
              ©{`${year}`} {content?.footer.copyright.text1}
            </h5>
            <h5>{content?.footer.copyright.text2}</h5>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  .footer-wrap {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    border-top: 1px solid #fcfcfc;
    padding-top: 5rem;
    .menu-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      .logo {
        width: 60px;
        height: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .menu {
        display: flex;
        gap: 24px;
        a {
          text-decoration: none;
          .list {
            color: #fcfcfc;
            font-size: 14px;
            letter-spacing: -0.5px;
            font-weight: 500;
            &:hover {
              color: rgb(10, 157, 91);
            }
          }
        }
      }
    }
    .social-wrap {
      display: flex;
      gap: 16px;
      a {
        text-decoration: none;
        width: 24px;
        height: 24px;
        svg {
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          cursor: pointer;
          width: 100%;
          height: 100%;
          &:hover {
            path {
              transition: 0.3s;
              fill: rgb(10, 157, 91);
            }
          }
        }
      }
    }
    .copyright {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 0;
      width: 100%;
      h5 {
        color: #fcfcfc;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.5px;
      }
    }
  }
  @media screen and (max-width: 996px) {
    .footer-wrap {
      padding: 4rem 0;
      align-items: flex-start;
      .menu-wrap {
        flex-direction: column;
        gap: 3rem;
        .menu {
          flex-direction: column;
        }
      }
      .copyright {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .container {
      width: inherit;
      margin: 0 24rem;
      .footer-wrap {
        .menu-wrap {
          .logo {
            img {
              width: 90px;
              height: 40px;
            }
          }
          .menu {
            a {
              .list {
                font-size: 18px;
              }
            }
          }
        }
        .copyright {
          h5 {
            font-size: 18px;
          }
        }
      }
    }
  }
`;
