import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ComingSoon from "./components/ComingSoon";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  return (
    // <ComingSoon/>
    <Container>
      <Header />
      <Home />
      <Footer />
    </Container>
  );
};

export default App;

const Container = styled.div``;
