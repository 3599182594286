import styled from "styled-components";

const Card = ({ data }) => {
  return (
    <Container>
        <div className="box">
          <img src={data.img} alt="icon" />
          <h1>{data.title}</h1>
          <p>{data.text}</p>
        </div>
    </Container>
  );
};

export default Card;

const Container = styled.div`
  .box {
    width: 100%;
    backdrop-filter: blur(20px);
    background-color: #1f1f1f;
    border-radius: 15px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    height: 100%;
    h1 {
      font-size: 16px;
      letter-spacing: -1px;
      margin: 0;
      color: #fcfcfc;
      width: 165px;
      line-height: 130%;
    }
    p {
      min-width: 167px;
      max-width: 200px;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: -1px;
      line-height: 180%;
      color: #cacbcb;
    }
  }
  @media screen and (max-width: 996px) {
    .box {
      p {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .box {
      h1 {
        font-size: 22px;
      }
      p {
        font-size: 18px;
        min-width: 220px;
        max-width: 260px;
      }
    }
  }
`;
