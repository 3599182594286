import styled from "styled-components";
import { useState } from "react";
import Translation from "../data/data.json";
import { Link } from "react-router-dom";
import Contact from "../components/Contact";

const getLocalLang = () => {
  let lang = localStorage.getItem("lang");

  if (lang) {
    return localStorage.getItem("lang");
  } else {
    return "EN";
  }
};

const Header = () => {
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const [toggle, setToggle] = useState(0);
  const handleClick = (e) => {
    setToggle(e);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const content =
    getLocalLang() === "EN" ? Translation.english : Translation.mongolia;

  return (
    <Container>
      <div className={`head ${isActive ? "active" : ""}`}>
        <div className="container">
          <div className="header-wrap">
            <div className="left-wrap">
              <div className="logo">
                <Link to="/">
                  <img src="/img/sirowhite.svg" alt="Logo" />
                </Link>
              </div>
              <div
                className={`burger ${isActive ? "active" : ""}`}
                onClick={toggleClass}
              >
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
            {isActive ? (
              <div className="right-wrap active">
                <div className="left">
                  <div className="menu-wrap">
                    {content?.header.menulist.map((data, i) => (
                      <Link to={data.url} key={i}>
                        <div
                          className={`menu ${toggle === i + 1 ? "active" : ""}`}
                          onClick={() => handleClick(i + 1)}
                        >
                          {data.menu}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="right">
                  {/* <div className="lang-wrap">
                    <h5>{content?.header.lang}</h5>
                    {getLocalLang() === "EN" ? (
                      <div
                        className="lang"
                        onClick={(e) => {
                          localStorage.setItem("lang", "MN");
                          window.location.reload();
                        }}
                      >
                        <img src="/img/flag-mn.svg" alt="flag" />
                      </div>
                    ) : (
                      <div
                        className="lang"
                        onClick={(e) => {
                          localStorage.setItem("lang", "EN");
                          window.location.reload();
                        }}
                      >
                        <img src="/img/flag-en.svg" alt="flag" />
                      </div>
                    )}
                  </div> */}
                  <button
                    class="button"
                    type="button"
                    data-hover="Click it"
                    data-active="I'M ACTIVE"
                    onClick={() => setModalOpen(true)}
                  >
                    <span>{content?.header.button.text}</span>
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="left">
                  <div className="menu-wrap">
                    {content?.header.menulist.map((data, i) => (
                      <Link to={data.url} key={i}>
                        <div
                          className={`menu ${toggle === i + 1 ? "active" : ""}`}
                          onClick={() => handleClick(i + 1)}
                        >
                          {data.menu}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="right">
                  {/* <div className="lang-wrap">
                    <h5>{content?.header.lang}</h5>
                    {getLocalLang() === "EN" ? (
                      <div
                        className="lang"
                        onClick={(e) => {
                          localStorage.setItem("lang", "MN");
                          window.location.reload();
                        }}
                      >
                        <img src="/img/flag-en.svg" alt="flag" />
                      </div>
                    ) : (
                      <div
                        className="lang"
                        onClick={(e) => {
                          localStorage.setItem("lang", "EN");
                          window.location.reload();
                        }}
                      >
                        <img src="/img/flag-mn.svg" alt="flag" />
                      </div>
                    )}
                  </div> */}
                  <button
                    class="button"
                    type="button"
                    data-hover="Click it"
                    data-active="Let's Talk"
                    onClick={() => setModalOpen(true)}
                  >
                    <span>{content?.header.button.text}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M 1.346 8.668 L 13.057 8.668 L 10.862 10.86 C 10.232 11.49 11.177 12.437 11.807 11.807 L 13.805 9.807 L 15.141 8.471 C 15.4 8.211 15.4 7.789 15.141 7.529 L 11.805 4.193 C 11.678 4.062 11.503 3.99 11.321 3.991 C 11.049 3.992 10.804 4.158 10.703 4.41 C 10.602 4.663 10.664 4.952 10.86 5.14 L 13.06 7.332 L 1.31 7.332 C 0.386 7.38 0.454 8.713 1.346 8.668 Z"
                        fill="#fcfcfc"
                      ></path>
                    </svg>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {modalOpen !== false && <Contact setOpen={setModalOpen} />}
    </Container>
  );
};

export default Header;

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
  opacity: 1;
  backdrop-filter: blur(20px);
  font-family: "Inter", sans-serif;
  .head {
    &.active {
      backdrop-filter: blur(20px);
    }
    .container {
      .header-wrap {
        display: flex;
        align-items: center;
        padding: 18px 0;
        gap: 45px;
        .left-wrap {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .logo {
            cursor: pointer;
            a {
              img {
                width: 60px;
                height: 30px;
              }
            }
          }
          .burger {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: end;
            gap: 4px;
            cursor: pointer;
            .line {
              width: 24px;
              height: 2px;
              background-color: #fff;
              border-radius: 3px;
              transition: all 0.3s;
              &:nth-of-type(1) {
                animation: bar1revAnim 0.3s ease-in-out forwards;
              }
              &:nth-of-type(3) {
                animation: bar3revAnim 0.3s ease-in-out forwards;
              }
              &:nth-of-type(2) {
                width: 12px;
              }
            }
            &.active {
              .line {
                background-color: #fff;
                &:nth-of-type(2) {
                  transform: scale(0);
                  opacity: 0;
                }
                &:nth-of-type(1) {
                  animation: bar1Anim 0.3s ease-in-out forwards;
                }
                &:nth-of-type(3) {
                  animation: bar3Anim 0.3s ease-in-out forwards;
                }
              }
            }
          }
        }
        .left {
          display: none;
        }
        .right {
          display: none;
        }
        .right-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          position: fixed;
          top: 66px;
          right: -288px;
          width: 26rem;
          gap: 2rem;
          &.active {
            right: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 5, 3, 0.85);
            backdrop-filter: blur(20px);
            padding: 25px;
            .left {
              align-items: flex-start;
              width: 100%;
              .menu-wrap {
                align-items: flex-start;
                .menu {
                  font-size: 15px;
                  color: #fff;
                  font-weight: 400;
                }
              }
            }
            .right {
              align-items: flex-start;
              width: 100%;
              .lang-wrap {
                .lang {
                  img {
                    width: 26px;
                    height: 26px;
                  }
                }
              }
              button {
                width: 100%;
                padding: 1.5rem 0;
              }
            }
          }
          .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 45px;
            width: 34rem;
            .menu-wrap {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 3rem;
              a {
                text-decoration: none;
                .menu {
                  font-size: 13px;
                  color: #fff;
                  font-weight: 400;
                  cursor: pointer;
                  &:hover {
                    color: rgb(10, 157, 91);
                  }
                }
              }
            }
          }
          .right {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 34rem;
            gap: 2rem;
            .lang-wrap {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              h5 {
                font-size: 16px;
                letter-spacing: -1px;
                margin: 0;
                color: #fcfcfc;
                font-weight: 400;
              }
              .lang {
                font-size: 13px;
                color: #444444;
                font-weight: 400;
                cursor: pointer;
                &:hover {
                  color: rgb(10, 157, 91);
                }
              }
            }
            button {
              background-color: rgb(31, 31, 31);
              font-size: 13px;
              font-weight: 500;
              color: #fff;
              border-radius: 15px;
              padding: 10px 16px;
              border: none;
              a {
                text-decoration: none;
                color: white;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 996px) {
    .head {
      .container {
        .header-wrap {
          justify-content: space-between;
          .left-wrap {
            /* width: 166px; */
            width: 107px;
            .burger {
              display: none;
            }
            .logo {
              width: 59px;
              height: 30px;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
          .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: auto;
            .menu-wrap {
              display: flex;
              align-items: center;
              flex-direction: row;
              gap: 3rem;
              a {
                text-decoration: none;
                .menu {
                  font-size: 13px;
                  color: #fcfcfc;
                  font-weight: 400;
                  cursor: pointer;
                  &:hover {
                    color: rgb(10, 157, 91);
                  }
                }
                .active {
                  color: rgb(10, 157, 91);
                }
              }
            }
          }
          .right {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 30px;
            width: auto;
            .lang-wrap {
              display: flex;
              align-items: center;
              width: auto;
              h5 {
                display: none;
              }
              .lang {
                font-size: 13px;
                color: #444444;
                font-weight: 400;
                cursor: pointer;
                &:hover {
                  color: rgb(10, 157, 91);
                }
              }
            }
            /* .button */
            .button {
              font-size: 13px;
              font-weight: 500;
              color: #fff;
              border-radius: 30px;
              padding: 10px 16px;
              border: 1px solid #fcfcfc;
              display: inline-block;
              position: relative;
              overflow: hidden;
              text-decoration: none;
              outline: none;
              display: flex;
              align-items: center;
              background-color: rgba(0, 0, 0, 0);
              gap: 8px;
              span {
                transition: 0.2s;
                transition-delay: 0.1s;
                font-size: 13px;
                font-weight: 600;
                color: #fcfcfc;
                letter-spacing: -0.5px;
              }
              svg{
                transition: 0.2s;
                transition-delay: 0.1s;
              }
              &::before,
              &::after {
                content: "";
                position: absolute;
                left: -10px;
                width: 100%;
                text-align: center;
                opacity: 0;
                border-radius: 30px;
                transition: 0.1s, opacity 0.4s;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              &::before {
                content: attr(data-hover);
                color: #fff;
                height: 100%;
              }
              /* &::after {
                content: attr(data-active);
                &::before {
                  transition-delay: 0s;
                }
              } */
              &:hover,
              &:active {
                border: 1px solid rgb(10, 157, 91);
                background-color: rgb(10, 157, 91);
                span {
                  opacity: 0;
                  transform: scale(0.3);
                }
              }
              &:hover::before,
              &:active::after {
                opacity: 1;
                transform: translate(0, 0);
                transition-delay: 0s;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .head {
      .container {
        width: inherit;
        margin: 0 24rem;
      }
    }
  }

  @keyframes bar1Anim {
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(5px) rotateZ(45deg);
    }
  }
  @keyframes bar3Anim {
    50% {
      transform: translateY(-6px);
    }
    100% {
      transform: translateY(-7px) rotateZ(135deg);
    }
  }
  @keyframes bar1revAnim {
    0% {
      transform: translateY(5px) rotateZ(45deg);
    }
    50% {
      transform: translateY(5px) rotateZ(0deg);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes bar3revAnim {
    0% {
      transform: translateY(-5px) rotateZ(135deg);
    }
    50% {
      transform: translateY(-5px) rotateZ(0deg);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;
